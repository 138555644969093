<template>
  <section>
    <div class="section-header flex justify-between items-center">
      <div>
        <h2>{{ $t('page.posts.review_title') }}</h2>
        <small
          v-html="$t('page.posts.review_description')"
        />
      </div>
      <div class="flex justify-end" v-if="canReviewContent">
        <router-link :to="{ name: 'review-posts' }">
          {{ $t('action.see_more') }}
        </router-link>
      </div>
    </div>

    <blocked-review-banner v-if="!canReviewContent && isEmpty"/>
    
    <home-posts-container
      v-if="!isEmpty"
      :posts="posts"
      :loaded="isPostsLoaded"
      approvable
      rejectable
    />
  
    <template v-if="isEmpty && canReviewContent">
      <div
        class="empty-notification-card card card px-8 py-4 flex flex-col justify-center items-center mx-auto w-full lg:w-3/4"
      >
        <div class="flex flex-col md:flex-row md:space-x-8 items-center">
          <img
            class="transform rotate-6 w-1/2 sm:w-1/3 md:w-auto mx-auto"
            src="@/assets/manyrobot.svg"
            alt="manyrobot"
            style="max-width: 230px"
          />
          <div
            class="text-lg font-light text-center md:text-left leading-relaxed"
            v-html="hasNoPostMessage"
          ></div>
        </div>
      </div>
    </template>
  </section>
</template>

<script lang="ts">
import { useReviewPosts } from '@/api/publications/usePosts'
import { computed, defineComponent, onMounted, unref } from 'vue'
import HomePostsContainer from '@/components/home/HomePostsContainer.vue'
import { useI18n } from 'vue-i18n';
import { usePaidQuota } from '@/composables/profile/useQuota'
import { useProfile } from '@/composables/profile/useProfile'
import BlockedReviewBanner from '@/components/posts/blockedReviewBanner.vue'

export default defineComponent({
  components: {
    HomePostsContainer,
    BlockedReviewBanner,
  },

  setup () {
    const { t } = useI18n()
    
    const {
      isPostsLoaded,
      posts,
      fetchPosts
    } = useReviewPosts()

    const {
      quota,
      birtday
    } = usePaidQuota()

    const {
      lockAccess,
      userRules
    } = useProfile()

    const isEmpty = computed(() => {
      return unref(isPostsLoaded) && unref(posts).length === 0
    })

    const hasNoPostMessage = computed(() => {
      const messages = {
        creating: t('state.reviews.creating_posts_message-html'),
        alreadUsed: t('state.reviews.alread_review_posts_message-html', { birtday }),
        noContent: t('state.reviews.no_posts_message-html', { birtday })
      }
      if (unref(quota).limit === 0) {
        return messages.noContent
      } else if (unref(quota).current < unref(quota).limit) {
        return messages.creating
      }
      return messages.alreadUsed
    })

    const canReviewContent = computed(() => {
      return !lockAccess.value || userRules.value.canReviewContent
    })

    onMounted(async () => {
      await fetchPosts()
    })
    
    return {
      posts,
      isPostsLoaded,
      isEmpty,
      hasNoPostMessage,
      canReviewContent
    }
  }
})
</script>

<style>
@layer components {
  /* .empty-notification-card {
    @apply bg-gradient-to-br from-cyan-300 to-indigo-900;
  }

  .empty-notification-card * {
    @apply text-white;
  } */

  .empty-notification-card h4 {
    @apply font-semibold;
  }
}
</style>