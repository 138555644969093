<template>
  <div v-if="!loaded">
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mb-8">
      <div
        class="card animate-pulse flex flex-col relative"
        v-for="n in 3" :key="`preview-${n}`"
      >
        <div class="bg-gray-300 w-full h-64 rounded-tl-lg rounded-tr-lg"></div>
        <div class="flex-auto p-4">
          <div
            class="whitespace-normal font-flow text-3xl leading-5 text-gray-300"
          >
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure, eum natus enim.
          </div>
        </div>
        <div class="card-footer flex items-center justify-end">
          <div class="bg-gray-300 rounded h-10 w-2/5"></div>
        </div>
      </div>
    </div>
  </div>

  <swiper
    v-bind="swiperSettings"
    navigation
  >
    <swiper-slide
      class="p-2"
      v-if="hasSlot('first-item')"
    >
      <slot name="first-item" />
    </swiper-slide> 

    <swiper-slide
      class="p-2"
      v-for="post in posts"
      :key="post.id"
    >
      <post-card
        :key="post.uid"
        :post="post"
        :editable="editable"
        :rejectable="rejectable && !lock"
        :approvable="approvable && !lock"
        :lock="lock"
      />
    </swiper-slide>
  </swiper>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PostCard from '@/components/posts/PostCard.vue'

// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper'

SwiperCore.use([Navigation])

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    PostCard
  },

  props: {
    posts: {
      type: Object,
      required: true
    },
    loaded: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    rejectable: {
      type: Boolean,
      default: false
    },
    approvable: {
      type: Boolean,
      default: false
    },
    lock: {
      type: Boolean,
      default: false
    }
  },

  setup (props, { slots }) {
    const swiperSettings = {
      loop: false,
      slidesPerView: 1,
      slideToClickedSlide: true,
      preventInteractionOnTransition: true,
      freeMode: true,
      breakpoints: {
        640: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 2
        },
        1280: {
          slidesPerView: 3
        },
        1536: {
          slidesPerView: 4
        }
      }
    }

    const hasSlot = (name: string) => {
      return !!slots[name]
    }

    return {
      swiperSettings,
      hasSlot
    }
  }
})
</script>

<style>
  .swiper-slide {
    height: auto;
  }

  .post-card {
    height: 100%
  }
</style>

<style scoped>
  .list-move,
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }

  .list-leave-active {
    /* position: absolute; */
  }
</style>

