<template>
  <section>
    <div class="section-header flex justify-between items-center">
      <div>
        <h2>{{ $t('nav.sidebar.my_contents') }}</h2>
        <small
          v-html="$t('page.posts.my_content_description')"
        />
      </div>
      <div class="flex justify-end">
        <router-link :to="{ name: 'my-posts' }">
          {{ $t('action.see_more') }}
        </router-link>
      </div>
    </div>
    
    <home-posts-container
      :posts="posts"
      :loaded="isPostsLoaded"
      :approvable=false
      editable
    >
    <template v-slot:first-item>
      <router-link :to="{ name: 'create-post' }" class="add-card">
        <div
          class="post-card card card-hover flex flex-col justify-center items-center p-4 h-full"
          style="max-height: 38rem;"
        >
          <div class="flex flex-col items-center gap-4 text-blue-600">
            <i class="fas fa-plus fa-2x block hover:no-underline"></i>
            {{ $t('page.my_content.action.new_content') }}
          </div>
        </div>
      </router-link>
    </template>
    </home-posts-container>
  </section>
</template>

<script lang="ts">
import { useMyPosts } from '@/api/publications/usePosts'
import { computed, defineComponent, onMounted, unref } from 'vue'
import HomePostsContainer from '@/components/home/HomePostsContainer.vue'

export default defineComponent({
  components: {
    HomePostsContainer,
  },

  setup () {

    const {
      isPostsLoaded,
      fetchPosts,
      preview: posts
    } = useMyPosts()

    const isEmpty = computed(() => {
      return unref(isPostsLoaded) && unref(posts).length === 0
    })

    onMounted(async () => {
      await fetchPosts()
    })
    
    return {
      posts,
      isPostsLoaded,
      isEmpty
    }
  }
})
</script>

<style>
@layer components {
  .add-card,
  .add-card i {
    @apply hover:no-underline !important;
  }
}
</style>